.menu {
  box-shadow:
    rgb(0 0 0 / 12%) 0 1px 6px,
    rgb(0 0 0 / 12%) 0 1px 4px;
  border-radius: 2px;
  background: var(--tunein-grey);
  min-width: 120px;
  overflow: auto;

  .item {
    outline: none;
  }

  .itemContent {
    display: block;
    width: 100%;
    text-align: left;
    line-height: 48px;
    margin: 0;
    padding: 0 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: var(--base-transition);
  }

  .buttonItem {
    border: none;
    background: none;

    &:hover {
      background: rgb(0 0 0 / 10%);
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .subMenuTrigger {
    position: relative;
  }

  .subMenuTriggerElement:hover {
    background: rgb(0 0 0 / 10%);
    cursor: pointer;
  }
}

.divider {
  display: block;
  background: var(--elephant-grey);
  height: 1px;
  margin: 0;
}

.chevronRight {
  content: '';
  background: transparent url('/assets/img/shared/chevron-right-bold.svg')
    no-repeat;
  display: block;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
}
