.container {
  display: inline-flex;
}

.divider {
  align-self: center;
  margin-right: var(--default-margin-px);
  margin-left: var(--default-margin-px);
  color: #fff;
  font-size: var(--font-size-1);
}

.dividerSpace {
  display: block;
  width: 1rem;
}

.authLink {
  align-self: center;
  font-size: var(--font-size-3);
}
