@mixin mainNavTextLink() {
  font-size: var(--font-size-2);

  @media (--min-width-xlarge) {
    font-size: var(--font-size-3);
  }
}

@mixin stickyNavTextLink() {
  & {
    color: white !important;
  }

  i {
    background-color: white !important;
  }
}

.wideHeader {
  display: none;
  width: 100%;
  padding-top: 36px;
  position: absolute;
  top: 0;
  z-index: var(--top-z-index);

  @media (--min-width-large) {
    display: block;
  }

  .logologo,
  .stickyListenNow,
  .navDivider {
    display: none;
    float: left;
  }

  .landingSplashHeaderBackground {
    display: block;
  }

  .logologo {
    margin-left: 24px;
    margin-right: 24px;
    position: relative;
    bottom: 15px;
    width: 100px;
    height: 44px;
  }

  .stickyListenNow {
    @include mainNavTextLink;
    @include stickyNavTextLink;

    & {
      margin-right: 20px;
    }
  }

  .navDivider {
    height: 24px;
    width: 2px;
    background-color: var(--ink-light);
    margin-right: 21px;
  }

  .mainNavLinks {
    margin-left: var(--main-nav-links-margin-left);

    a {
      float: left;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &.mainNavLink {
        @include mainNavTextLink;
      }

      &.headerButton {
        position: relative;
        bottom: 15px;
        display: block;
        margin-right: var(--main-nav-links-header-button-margin-right);
        font-size: var(--font-size-2);
        width: var(--main-nav-links-header-button-width);
        height: 48px;

        &.landingSplashHeaderPadding {
          margin-left: 12px;
        }

        @media (--max-width-xlarge) {
          margin-right: 1rem;
        }
      }
    }
  }

  .authLinks {
    float: right;
    margin-left: 20px;
    margin-right: 20px;
    text-align: right;

    a {
      @include mainNavTextLink;
    }

    .myAccount span {
      @include mainNavTextLink;

      & {
        margin-right: 10px;
        color: var(--white);
      }
    }

    &.invertedColors {
      color: var(--white);
    }

    @media (--min-width-xlarge) {
      margin-left: 25px;
      margin-right: 32px;
      width: 200px;
    }
  }

  &.positionOne {
    opacity: 0;
    top: -100px;
    position: fixed;
  }

  &.positionTwo {
    transition: top 0.4s ease-in;
    opacity: 1;
    background-color: var(--secondary-color-5);
    border-bottom: 2px solid var(--ink-dark);
    padding-bottom: 7px;
    z-index: 11;

    a,
    .authLinks a,
    .myAccount span {
      @include stickyNavTextLink;
    }

    .logologo,
    .stickyListenNow,
    .navDivider {
      display: block;
    }

    a.headerButton {
      display: none;
    }
  }

  &.positionThree {
    top: 0;
  }
}
