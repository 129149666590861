.container {
  position: relative;

  @supports (-moz-appearance: none) {
    contain: content;
  }
}

.menuOpen {
  @supports (-moz-appearance: none) {
    contain: inherit;
  }
}

.button {
  border: none;
  background: none;

  &:active,
  &:focus {
    outline: none;
  }
}

.menu {
  color: var(--secondary-color-5);
  position: absolute;
  z-index: var(--top-z-index);
  top: 100%;
  right: 0;
}
