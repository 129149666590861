.content {
  display: inline-table;
  margin-bottom: 16px;

  @media (--min-width-small) {
    float: left;
    width: 48.5%;
  }

  @media (--min-width-medium) {
    margin-bottom: 48px;
  }

  @media (--min-width-large) {
    margin-bottom: 64px;
    width: 100%;
  }
}

.images {
  position: relative;
  display: table;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 3px;

  &.needsBorder {
    border: solid 1px rgb(0 0 0 / 10%);
  }
}

.correctRightImageOverlay {
  margin-right: auto;
  margin-left: 0;
  width: 63.5%;

  img {
    margin: 0;
    max-width: 100%;
  }
}

.correctLeftImageOverlay {
  margin-right: 0;
  margin-left: auto;
  width: 63.5%;

  img {
    margin: 0;
    max-width: 100%;
  }
}

.padTop {
  margin-top: 25%;
}

.imageSectionLeft {
  position: relative;
  display: block;
  float: left;
  padding-right: 4px;
  width: 50%;

  @media (--min-width-small) {
    padding-right: 8px;
  }
}

.imageSectionLeft img {
  margin-right: 0;
  margin-left: auto;
}

.imageContainer {
  position: relative;
  margin-bottom: 8px;

  @media (--min-width-small) {
    margin-bottom: 16px;
  }
}

.imageSectionRight {
  position: relative;
  display: block;
  float: left;
  padding-left: 4px;
  width: 50%;

  @media (--min-width-small) {
    padding-left: 8px;
  }
}

.imageSectionRight img {
  margin-right: auto;
  margin-left: 0;
}
