.topSection {
  position: relative;
  display: block;
  height: 370px;
  padding-top: 88px;
  background-color: black;

  @media (--min-width-xsmall) {
    padding-top: 80px;
    height: 460px;
  }

  @media (--min-width-small) {
    padding-top: 80px;
    height: 655px;
  }

  @media (--min-width-medium) {
    padding-top: 80px;
    height: 655px;
  }

  @media (--min-width-large) {
    padding-top: 20px;
    height: 545px;
  }

  @media (--min-width-xlarge) {
    height: 640px;
  }
}

/**
 * NOTE: In order to optimize the landing page for SEO, 
 * we are implementing the background image as an inline style and passing the url to preload in the head.
 * To avoid having multiple sources of truth we should only update the image url in one place and not set
 * the background image in the css below.
 * @reference Landing.js landingHeaderBackgroundImageMap
 */
.landingSplashHeaderBackground {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--secondary-color-5);
  height: 400px;

  @media (--min-width-medium) {
    height: 500px;
  }
}

.searchBarContainer {
  display: none;
  position: relative;
  background-color: white;
  padding: 32px 0;
  margin: 0 32px;
  z-index: 3;
  top: calc(-1 * var(--landing-search-offset));
  border-radius: var(--landing-border-radius);
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%);

  @media (--min-width-large) {
    display: block;
  }

  @media (--min-width-xlarge) {
    max-width: var(--landing-max-width);
    margin: 0 auto;
  }

  input {
    color: var(--secondary-color-5);
    padding-left: 60px !important;

    &::placeholder {
      /* Chrome/Opera/Safari */
      opacity: 0.5;
      font-size: var(--font-size-6);
      line-height: 1.14;
      color: var(--secondary-color-5);
    }
  }
}

.searchBarContainer > div > div:last-child {
  height: 32px !important;
  font-size: var(--font-size-6) !important;
  line-height: 32px !important;
}

.searchBar {
  padding-left: 0;
  border-radius: 0;
  background-color: white;
}

.searchIconContainer {
  background-color: transparent;
  position: absolute;
  width: 26px;
  height: 26px;
  top: 4px;
  left: 30px;
}

.button {
  display: block;
  width: 160px;
  height: 48px;
  margin: auto;

  span {
    font-size: var(--font-size-2);
    font-weight: 500;
    line-height: 1;
  }
}

input:focus::placeholder {
  opacity: 0;
}

.textSection {
  display: block;
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-regular);
  line-height: 1.27;
  text-align: center;
  margin: 64px 32px 24px;

  @media (--min-width-small) {
    width: 74.5%;
    margin: 64px auto 24px;
  }

  @media (--min-width-large) {
    width: 76%;
  }

  @media (--min-width-large) {
    width: 77%;
    margin: 48px auto 32px;
    font-size: var(--font-size-6);
    max-width: 864px;
  }

  @media (--min-width-xlarge) {
    font-size: var(--font-size-7);
    margin-bottom: 49px;
  }
}

.startListeningButton {
  color: white;
}

.startListeningButton2 {
  border-color: white;
  width: 100%;

  span {
    color: white;
  }
}

.startFreeTrialButton {
  width: 100%;
}

.upsellContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 32px;
  padding: 32px;
  border-radius: var(--landing-border-radius);
  background-color: var(--grey-3);

  @media (--min-width-small) {
    padding: 40px;
  }

  @media (--min-width-large) {
    flex-direction: row;
    padding: 48px 64px;
    margin: 128px 36px;
  }

  @media (--min-width-xlarge) {
    max-width: var(--landing-max-width);
    margin: 128px auto;
  }
}

.upsellText {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.18;
  text-align: center;
  padding-bottom: 24px;
  display: flex;

  @media (--min-width-large) {
    font-size: 26px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
    padding-bottom: 0;
    max-width: 75%;
  }
}

.upsellButtonLink {
  display: flex;
  justify-content: center;

  @media (--min-width-large) {
    margin-right: 0;
    margin-left: auto;
  }
}

.newUpsellContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 32px;
  padding: 32px;
  border-radius: var(--landing-border-radius);
  border: 2px solid var(--t-sharp);
  background-image: url('/assets/img/landing/pattern-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--secondary-color-5);

  @media (--min-width-small) {
    padding: 40px;
  }

  @media (--min-width-large) {
    flex-direction: row;
    padding: 32px 64px;
    margin: 25px 36px;
  }

  @media (--min-width-xlarge) {
    max-width: var(--landing-max-width);
    margin: 25px auto;
  }
}

.newUpsellLogosContainer {
  background-image: url('/assets/img/premium/upsell/upsell_logos_stacked.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (--min-width-large) {
    width: 50%;
  }
}

.newUpsellTextContainer {
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 24px;

  @media (--min-width-large) {
    font-size: 26px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
    padding-bottom: 0;
    width: 50%;
  }
}

.newUpsellTitle {
  color: var(--t-sharp);
  font-family: var(--primary-font-header-bold);
  font-size: 22px;
  line-height: 1;
  margin-left: 25px;

  @media (--min-width-small) {
    font-size: 26px;
  }
}

.newUpsellButtonLink {
  margin-left: 25px;
  justify-content: center;
  background-color: var(--premium-gold);
  color: var(--ink-dark);
  border-radius: 3px;
  width: 225px;

  span {
    font-weight: var(--font-weight-bold);
  }

  @media (--min-width-small) {
    width: 300px;
  }
}

.newUpsellDisclaimer {
  margin-left: 25px;
  color: white;
  font-size: var(--font-size-1);
  margin-top: 0;

  @media (--min-width-large) {
    margin-top: -10px;
  }
}

.tableText {
  text-align: left;
  font-weight: normal;
  font-size: var(--font-size-2);
  padding-left: 14px;
}

.splashHeaderTextContainer {
  text-align: center;

  @media (--min-width-large) {
    margin-top: 65px;
    text-align: center;
  }
}

.splashHeaderLogo {
  margin: 0 25px;

  img {
    width: 315px;

    @media (--min-width-medium) {
      width: 400px;
    }
  }
}

.splashHeaderTitle {
  color: white;
  font-family: var(--primary-font-header-bold);
  font-size: var(--font-size-7);
  line-height: 1;

  @media (--min-width-small) {
    font-size: 36px;
  }

  @media (--min-width-medium) {
    font-size: 50px;
  }
}

.splashHeaderSubtitle {
  color: var(--white);
  font-size: var(--font-size-5);
  line-height: 1;

  @media (--min-width-medium) {
    font-size: 26px;
  }

  @media (--min-width-large) {
    font-size: var(--font-size-7);
  }
}

// NOTE: Adding !important to solve EU specific landing page button styling seemingly caused by our mysterious css bundling issue
// https://tunein.atlassian.net/browse/TUNE-15775
.splashHeaderButtonLink {
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  justify-content: center;
  color: var(--ink-dark) !important;
  width: 170px !important;
  border-radius: 3px !important;
  float: none;
  margin: 16px auto;

  @media (--min-width-small) {
    float: none;
  }

  @media (--min-width-large) {
    float: none;
  }

  span {
    font-weight: var(--font-weight-bold) !important;

    &:hover {
      color: var(--ink-dark) !important;
      opacity: 0.5;
    }
  }
}

.splashHeaderDisclaimer {
  color: white;
  font-size: var(--font-size-1);
  margin-top: 0;

  @media (--min-width-large) {
    margin-top: 5px;
  }
}

.divider {
  background-color: #eeefee;
  border: solid 1px #eeefee;
  height: 2px;
  margin: 80px 16px;
  max-width: var(--landing-max-width);

  @media (--min-width-small) {
    margin: 80px 32px;
  }

  @media (--min-width-large) {
    margin: 128px 36px;
  }

  @media (--min-width-xlarge) {
    margin: 128px auto;
  }
}

.sectionHeader {
  font-family: var(--primary-font-header-bold);
  font-size: 34px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: var(--secondary-color-5);

  @media (--min-width-medium) {
    font-size: 36px;
    line-height: 0.94;
  }

  @media (--min-width-large) {
    font-size: 48px;
    line-height: 0.92;
  }
}

.sectionText {
  font-size: var(--font-size-3);
  line-height: 1.5;

  @media (--min-width-large) {
    font-size: var(--font-size-4);
  }
}

.premiumOfferingsBackground {
  margin-bottom: 80px;
  position: relative;
  background-image: url('/assets/img/landing/premium-background-pattern-v2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--secondary-color-5);
  padding: 80px 0;
  width: 100%;

  @media (--min-width-large) {
    padding: 128px 0;
    margin-bottom: 128px;
  }
}

.premiumOfferingsContainer {
  margin: 0 32px;
  max-width: var(--landing-max-width);

  @media (--min-width-large) {
    margin: 0 36px;
  }

  @media (--min-width-xlarge) {
    margin: 0 auto;
  }
}

.premiumOfferings {
  width: 100%;

  @media (--min-width-small) {
    composes: clearfix from '../../styles/common.module.scss';
  }
}

.tableContainer {
  border-radius: var(--landing-border-radius);
  background-color: var(--secondary-color-5);
  border: solid 2px var(--ink-light);
  padding: 32px 24px 24px;
  margin-top: 32px;
  margin-bottom: 16px;

  p {
    color: white;
    font-size: var(--font-size-3);
    line-height: 1.38;
    padding-top: 4px;

    @media (--min-width-large) {
      font-size: var(--font-size-4);
      line-height: 1.44;
      padding-top: 8px;
    }

    span {
      font-family: var(--primary-font-header-bold);
      font-size: var(--font-size-7);
      font-weight: bold;
      line-height: 1;
      margin-right: 5px;

      @media (--min-width-large) {
        font-size: 34px;
        line-height: 0.9;
      }
    }
  }

  @media (--min-width-small) {
    float: left;
    width: 49%;
    margin-bottom: 0;
  }

  @media (--min-width-large) {
    margin-top: 48px;
    padding: 40px 32px 32px;
  }

  .premiumPricingLine {
    height: 28px;

    @media (--min-width-large) {
      height: 38px;
    }
  }
}

.tableContainerRight {
  margin: 0;

  @media (--min-width-small) {
    float: right;
    margin-top: 32px;
  }

  @media (--min-width-large) {
    margin-top: 48px;
  }
}

p.upsellHeader {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  line-height: 1.18;
  padding-bottom: 12px;

  @media (--min-width-large) {
    font-size: var(--font-size-6);
    line-height: 1.15;
    padding-bottom: 16px;
  }
}

.newUpsellTable {
  border-collapse: separate;
  border-spacing: 0 12px;
  color: var(--white);
    line-height: 1;
}

.table {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 24px 0;

  @media (--min-width-large) {
    margin: 32px 0 48px;
  }
}

.tableLeft {
  margin-top: 50px;

  @media (--min-width-large) {
    margin-top: 66px;
  }
}

.table td {
  color: white;
  font-size: var(--font-size-3);
  padding-bottom: 8px;

  @media (--min-width-large) {
    font-size: var(--font-size-4);
  }
}

.productDescription {
  text-align: left;
  padding-left: 8px;
}

.productDescriptionDisabled {
  color: white;
  opacity: 0.5;
}

.checkIcon {
  display: inline-block;
  background: transparent url('/assets/img/landing/icons/icon-check.svg') no-repeat;
  background-position: bottom -5px right 0;
  background-size: contain;
  width: 22px;
  height: 22px;

  @media (--min-width-large) {
    background-position: bottom -6px right 0;
    width: 24px;
    height: 24px;
  }
}

.checkPlaceholder {
  width: 22px;
  height: 22px;

  @media (--min-width-large) {
    width: 24px;
    height: 24px;
  }
}

.deviceOfferingsContainer {
  position: relative;

  @media (--min-width-small) {
    composes: clearfix from '../../styles/common.module.scss';
    width: 100%;
  }
}

.deviceTextAndLinks {
  padding: 32px 32px 0;

  @media (--min-width-small) {
    float: right;
    width: 51%;
    padding: 32px 32px 0 65px;
  }

  @media (--min-width-medium) {
    padding: 61px 32px 0 76px;
  }

  @media (--min-width-large) {
    padding: 162px 36px 0 94px;
  }

  @media (--min-width-xlarge) {
    padding: 162px 36px 0 106px;
  }
}

.devicesText {
  padding-top: 12px;
  padding-bottom: 24px;

  @media (--min-width-large) {
    padding-top: 16px;
    padding-bottom: 32px;
    max-width: 424px;
  }
}

.deviceLinksContainer span {
  font-size: var(--font-size-5);
  font-weight: 500;
  line-height: 1;
}

.appDownloadLink {
  margin-bottom: 5px;
}

.appleIcon {
  display: inline-block;
  background: transparent url('/assets/img/landing/icons/icon-apple.svg')
    no-repeat;
  background-position: bottom 0 right 0;
  background-size: contain;
  width: 135px;
  height: 45px;
}

.googlePlayIcon {
  display: inline-block;
  background: transparent url('/assets/img/landing/icons/icon-google-play.svg')
    no-repeat;
  background-position: bottom 0 right 0;
  background-size: contain;
  width: 135px;
  height: 40px;
}

.deviceOfferingsImages {
  background-image: url('/assets/img/landing/devices-small.jpg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 400px 400px;
  height: 400px;

  @media (--min-width-xsmall) {
    background-position: center;
  }

  @media (--min-width-small) {
    background-position: right;
    float: left;
    width: 49%;
    background-size: 380px 380px;
    height: 380px;
  }

  @media (--min-width-medium) {
    background-image: url('/assets/img/landing/devices-medium.jpg');
    background-size: 464px 464px;
    height: 464px;
  }

  @media (--min-width-large) {
    background-size: 608px 608px;
    height: 608px;
  }

  @media (--min-width-xlarge) {
    background-image: url('/assets/img/landing/devices-large-v2.jpg');
    background-size: 688px 688px;
    height: 688px;
  }
}

.discoverContainer {
  margin: 0 32px 36px;

  @media (--min-width-small) {
    margin: 0 32px 40px;
  }

  @media (--min-width-large) {
    margin: 0 36px 56px;
  }

  @media (--min-width-xlarge) {
    max-width: var(--landing-max-width);
    margin: 0 auto 56px;
  }
}

.sectionTextDiscover {
  margin-top: 12px;

  @media (--min-width-medium) {
    max-width: 584px;
  }

  @media (--min-width-large) {
    max-width: 688px;
  }
}

.finalCta {
  background-color: var(--secondary-color-5);
  padding: 66px 32px 68px;

  @media (--min-width-small) {
    padding: 80px 32px 82px;
  }

  @media (--min-width-large) {
    padding: 128px 36px 130px;
  }

  p {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-medium);
    line-height: 1.5;
    text-align: center;
    color: white;
    padding-bottom: 32px;

    @media (--min-width-large) {
      font-size: var(--font-size-6);
    }
  }
}

.finalCtaLinksContainer {
  @media (--min-width-small) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.finalCtaListenNowButton {
  width: 100%;
  height: 48px;
  background-color: white;
  margin-bottom: 16px;

  @media (--min-width-small) {
    margin: 0 8px;
    max-width: 160px;
  }

  span {
    color: var(--secondary-color-5);
  }
}

.finalCtaStartFreeTrialButton {
  border-color: white;
  width: 100%;
  height: 48px;

  @media (--min-width-small) {
    margin: 0 8px;
    max-width: 160px;
  }

  span {
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-medium);
  }
}

.finalCtaDivider {
  background-color: var(--secondary-color-5);

  > div {
    background-color: var(--ink-light);
    border-radius: 0.5px;
    height: 2px;
    margin: auto;

    @media (--min-width-small) {
      width: calc(100% - 64px);
    }

    @media (--min-width-large) {
      width: calc(100% - 72px);
    }

    @media (--min-width-xlarge) {
      width: var(--landing-max-width);
    }
  }
}

.srOnly {
  composes: screenReaderOnly from '../../styles/common.module.scss';
}
