.menuSelector {
  display: inline-flex;
  align-items: center;
}

.chevron {
  width: 16px;
  height: 9px;
}

.avatar {
  background-position: 50% 50%;
  background-size: cover;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
