.element {
  a,
  p {
    display: block;
    color: white;
    font-weight: bold;
    font-size: var(--font-size-6);
    font-family: var(--primary-font-header-bold);
    line-height: 0.92;

    &.showCTA {
      font-size: var(--font-size-3);
      line-height: 1;
    }

    @media (--min-width-small) {
      font-size: 34px;
      line-height: 0.88;

      &.showCTA {
        font-size: var(--font-size-4);
        line-height: 0.9;
      }
    }

    @media (--min-width-large) {
      font-size: 4.6vw;
      line-height: 4.2vw;

      &.showCTA {
        font-size: 36px;
        line-height: 1;
      }
    }

    @media (--min-width-xlarge) {
      font-size: 54px;
      line-height: 0.88;

      &.showCTA {
        font-size: 44px;
        line-height: 0.88;
      }
    }

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &.white {
      color: white;
    }

    &.grey {
      color: var(--grey-1);
    }

    &.ink {
      color: var(--secondary-color-5);
    }
  }
}
