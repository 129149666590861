@use 'sass:math';

@mixin aspectRatio($width, $height) {
  &::before {
    display: block;
    padding-top: math.div($height, $width) * 100%;
    width: 100%;
    content: '';
  }
}

.container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 32px;
  border-top-left-radius: var(--landing-border-radius);

  @media (--min-width-large) {
    left: auto;
  }

  @media (--min-width-xxxlarge) {
    right: 32px;
    border-top-right-radius: var(--landing-border-radius);
  }

  .content {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container,
.image {
  @include aspectRatio(3, 2);

  & {
    overflow: hidden;
    min-width: 288px;
    max-width: 100%;
    max-height: 192px;
  }

  @media (--min-width-xsmall) {
    max-height: 280px;
  }

  @media (--min-width-small) {
    min-width: 608px;
    max-height: 460px;
  }

  @media (--min-width-large) {
    min-width: 45vw;
    max-height: 450px;
    width: 45vw;
    height: 450px;
  }

  @media (--min-width-xlarge) {
    width: 372px;
    height: 520px;
  }

  @media (--min-width-xxlarge) {
    width: 526px;
  }

  @media (--min-width-xxxlarge) {
    min-width: 675px;
    max-width: 675px;
  }
}

.srOnly {
  composes: screenReaderOnly from '../../../styles/common.module.scss';
  top: 0;
}
