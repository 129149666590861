.parallaxScrollWrapperSmall {
  margin: 80px 32px;
}

.parallaxScrollContainer {
  position: relative;
  width: 100%;

  @media (--min-width-small) {
    composes: clearfix from '../../styles/common.module.scss';
  }

  @media (--min-width-large) {
    padding: 0 36px 64px;
  }

  @media (--min-width-xlarge) {
    max-width: var(--landing-max-width);
    padding: 0;
    margin: 80px auto;
  }
}

.parallaxScrollImagesContainer {
  @media (--min-width-large) {
    float: left;
    width: 49%;
  }
}

.parallaxScrollTextSmall {
  margin-bottom: 64px;

  @media (--min-width-small) {
    width: 51.5%;
    float: left;
    padding-left: 66px;
    padding-top: 48px;
  }

  @media (--min-width-medium) {
    padding-left: 76px;
  }

  @media (--min-width-large) {
    margin-bottom: 0;
  }
}

.parallaxScrollTextSmall h1 {
  font-family: var(--primary-font-header-bold);
  font-size: var(--font-size-7);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 12px;
}

.parallaxScrollTextSmall p {
  font-size: var(--font-size-4);
  line-height: 1.44;
  margin-bottom: 24px;
}

.parallaxScrollTextSmall a {
  font-size: var(--font-size-5);
  font-weight: 500;
  line-height: 1;
  text-align: left;
}

.parallaxScrollText {
  position: sticky;
  width: 51%;
  top: 162px;
  left: 49%;
  float: right;
  padding: 64px 0 140px 100px;
}

.parallaxScrollText h1 {
  @media (--min-width-large) {
    font-family: var(--primary-font-header-bold);
    font-size: 40px;
    font-weight: bold;
    line-height: 0.9;
    color: #d0d1d1;
    transition: color 0.5s linear;
  }
}

.parallaxScrollText p,
.parallaxScrollText a {
  color: var(--secondary-color-5);
  padding-top: 12px;
  padding-bottom: 24px;
  font-size: var(--font-size-3);
  line-height: 1.5;
}

.verticalDescription {
  position: absolute;
  opacity: 0;
  top: 0;
  max-width: 471px;

  &.active {
    position: relative;
    transition: opacity 0.5s ease-in;
    opacity: 1;
  }
}

h1.active {
  color: var(--secondary-color-5);
}

.playIcon {
  display: inline-block;
  background: transparent url('/assets/img/landing/icons/icon-play.svg')
    no-repeat;
  background-position: bottom -1px right 0;
  background-size: contain;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.parallaxListenNowLink {
  color: inherit;
}
