.tileLinksBackgroundContainer {
  background: linear-gradient(
    to bottom,
    rgb(255 255 255 / 100%) 10%,
    rgb(28 32 60 / 100%) 10%
  );
  margin-top: 32px;

  @media (--min-width-small) {
    margin-top: 40px;
  }

  @media (--min-width-large) {
    margin-top: 56px;
  }
}

.tileLinksContainer {
  margin: 0 27px;

  @media (--min-width-medium) {
    margin: 0 25px;
  }

  @media (--min-width-large) {
    margin: 0 27px;
  }

  @media (--min-width-xlarge) {
    max-width: 1138.5px;
    margin: auto;
  }
}

.tileLinksBlock {
  @media (--min-width-large) {
    max-width: none;
    display: inline-block;
    width: 50%;
  }
}

.secondTileLinksBlock {
  display: none;

  @media (--min-width-large) {
    display: inline-block;
  }
}

.tileLink img {
  display: block;
  width: 100%;
}

.tileLink {
  position: relative;
  display: inline-block;
  margin: 0 1% 7px;
  height: auto;

  @media (--min-width-xsmall) {
    margin-bottom: 9.5px;
  }

  @media (--min-width-small) {
    margin-bottom: 13px;
  }

  @media (--min-width-medium) {
    margin-bottom: 17px;
  }

  @media (--min-width-large) {
    margin: 0 1.7% 8px;
    margin-bottom: 18.5px;
  }

  @media (--min-width-xlarge) {
    margin-bottom: 20px;
  }
}

.tileLink a {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: var(--landing-border-radius);
}

.tileLinkSingle {
  width: 23%;

  &.needsBorder {
    border: solid 1px rgb(0 0 0 / 10%);
    border-radius: var(--landing-border-radius);
  }

  @media (--min-width-large) {
    width: 21.6%;
  }
}

.tileLinkDouble {
  width: 48%;

  @media (--min-width-large) {
    width: 46.6%;
  }
}
