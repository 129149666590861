.logoContainer {
  display: none;
  margin-top: 148px;
  margin-bottom: 33px;
  width: 136px;
  height: 60px;

  @media (--min-width-large) {
    display: block;
  }

  @media (--min-width-xlarge) {
    margin-top: 164px;
    width: 150px;
    height: 66px;
  }
}

.button {
  margin-top: 5px;
  border-radius: var(--landing-border-radius);
  background-color: var(--secondary-color-5);
  color: #fff;
  width: 88px;
  height: 32px;

  @media (--min-width-small) {
    width: 160px;
    height: 48px;
    margin-top: 10px;
  }

  span {
    font-size: var(--font-size-1);

    @media (--min-width-small) {
      font-size: var(--font-size-3);
    }
  }
}

.textWrapper {
  position: relative;
}

.textContainer {
  position: absolute;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &.showText {
    visibility: visible;
    opacity: 1;
  }
}

.tinyImgWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  width: 88px;
  height: 96px;
  border-top-right-radius: var(--landing-border-radius);

  @media (--min-width-small) {
    width: 136px;
    height: 152px;
  }

  @media (--min-width-large) {
    bottom: var(--landing-search-offset);
  }

  &.layerUp {
    z-index: 2;
  }
}

.tinyImgBgColor {
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.tinyImg {
  position: absolute;
  bottom: 8px;
  left: 0;
  visibility: hidden;
  width: 80px;
  height: 80px;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.5s ease-out;

  @media (--min-width-small) {
    bottom: 16px;
    width: 120px;
    height: 120px;
  }

  &.showTinyImg {
    visibility: visible;
    opacity: 1;
  }
}

.visible {
  visibility: visible;
}

.tileLink {
  img {
    width: 100%;
  }

  &.needsBorder {
    border: solid 1px rgb(0 0 0 / 10%);
    border-radius: var(--landing-border-radius);
  }

  a {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: var(--landing-border-radius);
  }
}

.controlDots {
  position: relative;
  top: 150px;
  display: none;
  max-width: 300px;

  @media (--min-width-large) {
    display: block;
    top: 180px;
  }

  li {
    transition: opacity 0.25s ease-in;
    opacity: 0.4;
    filter: alpha(opacity=40);
    background: var(--secondary-color-5);
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px;

    &.white {
      background: white;
    }

    &:hover {
      position: relative;
      top: 1px;
      margin: 0 5px;
      opacity: 0.4;
      width: 10px;
      height: 10px;
    }

    &.dotSelected {
      position: relative;
      top: 2px;
      margin: 0 4px;
      opacity: 1;
      width: 12px;
      height: 12px;
      cursor: default;
    }
  }
}
